import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BackButton from '../components/backButton'
import Button from '../components/button'
import * as styles from './styles/apply-page.module.css'
import { getTextCopy, getHtmlCopy } from '../helpers'
import find from 'lodash/find'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/*
  NOTE: If you update the form fields, you must also update the shadow form
  on the /received/ page to prevent processing issus with Netlify
*/

const Apply = ({ location, data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
  } = data.applyPage
  const jobPostings = data.jobPostings.nodes
  const { careersPageSlug } = data.careersPage
  const { jobPostingPageSlug } = data.jobPostingPage
  const { receivedPageSlug } = data.receivedPage

  const queryParams = new URLSearchParams(location.search)
  const applyFor = find(jobPostings, { slug: queryParams.get(`for`) })

  const jobTitle = applyFor ? applyFor.title : ``
  const department = applyFor ? applyFor.department : ``

  const backPageSlug = applyFor
    ? jobPostingPageSlug.replace(`{{slug}}`, applyFor.slug)
    : careersPageSlug
  const backButtonText = applyFor
    ? getTextCopy(content, 'page.apply.backToJobPosting')
    : getTextCopy(content, 'page.apply.backToCareers')

  const headerText = applyFor
    ? getTextCopy(content, 'page.apply.headerSpecific')
        .replace(`{{jobTitle}}`, applyFor.title)
    : getTextCopy(content, 'page.apply.headerGeneric')

  const [disable, setDisable] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifySubmission = async () => {
    if (!executeRecaptcha) {
      return
    }

    setDisable(true)

    try {
      const applyform = document.getElementById('applyForm')
      const formData = new FormData(applyform)

      const token = await executeRecaptcha('apply')

      await fetch('/.netlify/functions/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
        }),
      })
        .then(response => {
          // @todo should we do something else in this case?
          if (response.status === 500) {
            alert(
              'There was a problem submitting this form, please try again later'
            )
          }

          // @todo temporary send to a separate form bucket to make sure we aren't being too aggressive with threshold
          // also remove the form from netlify and the received page when satisfied
          if (response.status === 400) {
            formData.set('form-name', 'recaptured-apply')
          }

          return response.json()
        })
        .then(data => formData.set('score', data.score))

      sendSubmission(formData)
    } catch (e) {
      console.log(e)
      setDisable(false)
    }
  }

  const sendSubmission = data => {
    fetch('/', {
      method: 'POST',
      body: data,
    }).then(() => navigate(receivedPageSlug))
  }

  const handleSubmit = e => {
    e.preventDefault()
    verifySubmission()
  }

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout>
        <div className={styles.pageWrapper}>
          <div className={styles.pageHeader}>
            <Link to={backPageSlug}>
              <BackButton>{backButtonText}</BackButton>
            </Link>
            <h1 className={styles.header}>{headerText}</h1>
            <div
              className={styles.subheader}
              dangerouslySetInnerHTML={{
                __html: getHtmlCopy(content, 'page.apply.subheader'),
              }}
            />
          </div>
          <form
            id="applyForm"
            name="apply"
            method="POST"
            netlify-honeypot="verify"
            data-netlify="true"
            className={styles.formWrapper}
            action={receivedPageSlug}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <input type="hidden" name="form-name" value="apply" />
            <input type="hidden" name="score" value="" />
            <input
              type="hidden"
              name="session"
              value={
                typeof FS !== 'undefined' &&
                FS.getCurrentSessionURL() /* eslint-disable-line */
              }
            />
            <input type="hidden" name="job" value={jobTitle} />
            <input type="hidden" name="department" value={department} />
            <label>
              {getTextCopy(content, 'page.apply.name')}
              <input
                type="text"
                name="name"
                required
                pattern=".{3,}"
                title={getTextCopy(content, 'page.apply.nameError')}
              />
            </label>
            <label>
              {getTextCopy(content, 'page.apply.email')}
              <input
                type="email"
                name="email"
                required
                pattern=".{5,}"
                title={getTextCopy(content, 'page.apply.emailError')}
              />
            </label>
            <label>
              {getTextCopy(content, 'page.apply.phone')}
              <input
                type="tel"
                name="phone"
                required
                pattern=".{10,}"
                title={getTextCopy(content, 'page.apply.phoneError')}
              />
            </label>
            <label>
              {getTextCopy(content, 'page.apply.cover')}
              <textarea name="cover" required />
            </label>
            <label>
              {getTextCopy(content, 'page.apply.resume')}
              <input type="file" name="resume" required />
            </label>
            <p className={styles.verify}>
              <label>
                {getTextCopy(content, 'page.apply.verify')}
                <input type="text" name="verify" />
              </label>
            </p>
            <Button disabled={disable} action="primary" type="submit">
              {getTextCopy(content, 'page.apply.button')}
            </Button>
          </form>
        </div>
      </Layout>
    </>
  )
}

export default Apply

export const query = graphql`
  {
    applyPage: contentfulPage(name: { eq: "page.apply" }) {
      ...pageFields
    }
    jobPostings: allContentfulJobPosting {
      nodes {
        slug
        title
        department
      }
    }
    jobPostingPage: contentfulPage(name: { eq: "page.jobPosting" }) {
      jobPostingPageSlug: slug
    }
    careersPage: contentfulPage(name: { eq: "page.careers" }) {
      careersPageSlug: slug
    }
    receivedPage: contentfulPage(name: { eq: "page.received" }) {
      receivedPageSlug: slug
    }
  }
`
